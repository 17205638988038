import logo from '../../logo.svg';
import './App.css';
import { Suspense } from 'react';
import Bill from '../Bill/Bill';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider, RequireAuth } from '../../Context/AuthContext';
import NotFound from '../404/NotFound';
import Login from '../Login/Login'
import SignUp from '../Signup/Signup'
import ResetEmail from '../ResetPW/ResetEmail';
import ResetPW from '../ResetPW/ResetPW';
import User from '../Settings/User';

const Loader = () => (
  <div className="App">
    <div className="App-header">
      <img src={logo} className="App-logo" alt="" style={{width: "20vmax"}}/>
      <div style={{marginTop: "10px"}}>Loading...</div>
    </div>
  </div>
);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route index element={
              <RequireAuth>
                <Bill />
              </RequireAuth>
            } />
            <Route exact path="login" element={<Login />} />
            <Route exact path="reset" element={<ResetEmail />} />
            <Route exact path="reset/:token" element={<ResetPW />} />
            <Route exact path="register" element={<SignUp />}/>
            <Route exact path="user" element={<User />} />
        </Routes>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
