import * as React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RequireAuth, useAuth } from '../../Context/AuthContext';
import { LoadingButton } from '@mui/lab';
import UserService from '../../Services/user.service';

export default function User() {
  const [loading, setLoading] = React.useState(false)
  const [user, setUserData] = React.useState({username: "", email: "", name: "", address: "", zip: "", city: "", country: "", account: ""})
  const [t] = useTranslation()
  const auth = useAuth()
  
  React.useEffect(() => {
    UserService.getUserData().then((data) => {
      setUserData({ 
        ...user,
        ...data.data
      })
    }).catch(() => { auth.logout() })
  }, [auth])

  const save = (event) => {
    event.preventDefault();
    setLoading(true)
    const data = Object.fromEntries(new FormData(event.currentTarget));
    // eslint-disable-next-line no-console
    UserService.updateUserData(data).catch(() => {}).finally(() => setLoading(false))
  };

  return (
    <RequireAuth>
      <Typography component="h1" variant="h5">
        {t("Account Data")}
      </Typography>
      <Box component="form" onSubmit={save} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label={t("Email Address")}
              value={user.email}
              onChange={(e) => {user.email = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="email"
              variant="standard"
            />
          </Grid>
        </Grid>
        <Typography component="h1" mt={3} variant="h5">
          {t("Billing Data")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="name"
              label={t("Name")}
              value={user.name}
              onChange={(e) => {user.name = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="account"
              name="account"
              label={t("IBAN")}
              value={user.account}
              onChange={(e) => {user.account = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="iban"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address"
              name="address"
              label={t("Address")}
              value={user.address}
              onChange={(e) => {user.address = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="address-line1"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label={t("City")}
              value={user.city}
              onChange={(e) => {user.city = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="address-level2"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="state"
              name="state"
              label={t("State/Province/Region")}
              value={user.state}
              onChange={(e) => {user.state = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="state"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label={t("Zip / Postal code")}
              value={user.zip}
              onChange={(e) => {user.zip = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="postal-code"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="country"
              name="country"
              label={t("Country")}
              value={user.country}
              onChange={(e) => {user.country = e.target.value; setUserData({...user})} }
              fullWidth
              autoComplete="country"
              variant="standard"
            />
          </Grid>
              <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("Save")}
          </LoadingButton>
        </Grid>
      </Box>
    </RequireAuth>
  );
}