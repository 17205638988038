import api from './api'

const getUserData = () => {
  return api.get(`/user/`);
};

const updateUserData = (userChanges) => {
  return api.put(`/user/`, userChanges)
}

const UserService = {
  getUserData,
  updateUserData,
};

export default UserService;