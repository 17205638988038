import { createContext, useContext, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router";
import AuthService from '../Services/auth.service'

const auth = createContext(null);

export function useAuth() {
  return useContext(auth);
}

const { Provider } = auth

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(null)

  useEffect(() => {
    setUser(AuthService.getCurrentUser())
  }, [])

  // Sign in and set the user
  let login = (username, password) => { return AuthService.login(username, password).then(() => setUser(AuthService.getCurrentUser())) }
  let logout = () => { AuthService.logout(); setUser(null) }
  let resetEmail = (username) => { return AuthService.resetEmail(username)}
  let resetPW = (token, password) => { return AuthService.resetPW(token, password) }

  let value = { user, login, logout, resetEmail, resetPW };

  return <Provider value={value}>{children}</Provider>;
}

export const OnlyAuth = ({ children }) => {
  const auth = useAuth()
  
  if (auth.user != null)
    return children
  return null
}

export const OnlyNonAuth = ({ children }) => {
  const auth = useAuth()
  
  if (!auth.user)
    return children
  return null
}

// Content inside needs authentication.
// If we use this we redirect the user to /login if he isn't signed in already.
// Save the location into the location state.
export const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    return <Navigate to="/login" state={{from: location }} />
  }
  return children
}

// Content inside needs authentication.
// If we use this we redirect the user to /login if he isn't signed in already.
// Save the location into the location state.
export const RequireAuthLevel = ({ children, scope }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    return <Navigate to="/login" state={{from: location }} />
  }
  if(auth.user.scope < scope) {
    return <></>
  }
  return children
}

// Page is not accessible to authenticated users.
// Redirect them to the saved state in the location state or to the main page.
export const RequireNonAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  const to = location.state?.from?.pathname || "/";

  if (auth.user != null) {
    // Navigate to the "from" page (or home page) and reset the state.
    return <Navigate to={to} state={{}} />
  }
  return children
}