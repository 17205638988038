import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControlLabel, IconButton, Switch } from '@mui/material';

function combineAmountUnit(amount, unit) {
  if(unit != null && unit.length > 0)
    return amount + unit
  return amount
}

function splitAmountUnit(a) {
  let amount = a
  let amountUnit = ""
  let idx = a.indexOf(" ")
  if (idx !== -1) {
    amount = a.slice(0, idx)
    amountUnit = a.slice(idx)
  }
  return {amount: amount, amountUnit: amountUnit}
}

export default function UserForm(params) {
  const [t] = useTranslation()
  const articles = params.articles
  const setArticles = params.setArticles
  const vat = params.vat
  const setVat = params.setVat

  React.useEffect(() => {
    if(articles.length === 0)
      setArticles([{name: "", amount: "", price: 0 }])
  }, [articles, setArticles])
  
  const addArticle = () => setArticles([...articles, {name: "", amount: "", price: 0 }])
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("Articles")}
      </Typography>
      <Grid container spacing={3}>
        {articles.map((article, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="name"
                name="name"
                label={t("Name")}
                value={article.name}
                onChange={(e) => {article.name = e.target.value ;setArticles([...articles])} }
                fullWidth
                autoComplete="name"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                id="amount"
                name="amount"
                label={t("Amount")}
                value={combineAmountUnit(article.amount, article.amountUnit)}
                onChange={(e) => {let aU = splitAmountUnit(e.target.value); article.amount = aU.amount; article.amountUnit = aU.amountUnit; setArticles([...articles])} }
                fullWidth
                inputProps={{ pattern: "[0-9]+.?[0-9]* ?.*" }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                required
                id="price"
                name="price"
                label={t("Price")}
                value={isNaN(article.price) ? 0 : article.price.toString()}
                onChange={(e) => {article.price = parseFloat(e.target.value); setArticles([...articles])} }
                fullWidth
                inputProps={{ inputMode: 'decimal' }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <IconButton onClick={(e) => {articles.splice(idx, 1); setArticles([...articles]) }} >
                <DeleteIcon/>
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <IconButton color="secondary" aria-label="add" onClick={addArticle}>
            <AddIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            value="bottom"
            control={
              <Switch color="primary" onChange={(e) => {vat.use = e.target.checked; setVat({...vat})} } />
            }
            label={t("Use VAT")}
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            required
            id="price"
            name="price"
            label={t("Price")}
            disabled={!vat.use}
            value={vat.vat}
            onChange={(e) => {vat.vat = e.target.value ;setVat({...vat})} }
            fullWidth
            inputProps={{ inputMode: 'decimal' }}
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}