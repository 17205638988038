import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

export default function UserForm(params) {
  const bill = params.bill
  const setBillData = params.setBillData
  const debtor = params.debtor
  const setDebtor = params.setDebtor
  const [t] = useTranslation()
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("Bill")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="billnr"
            name="billnr"
            label={t("Bill Nr.")}
            value={bill.nr}
            onChange={(e) => {bill.nr = e.target.value ;setBillData({...bill})} }
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="currency"
            name="currency"
            label={t("Currency")}
            value={bill.currency}
            onChange={(e) => {bill.currency = e.target.value ;setBillData({...bill})} }
            fullWidth
            autoComplete="currency"
            variant="standard"
          />
        </Grid>
      </Grid>
      <Typography sx={{borderTop: "1px solid rgba(0, 0, 0, 0.12)"}} mt={3} pt={1} variant="h6" gutterBottom>
        {t("Debtor")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="debtor-name"
            name="name"
            label={t("Name")}
            value={debtor.name}
            onChange={(e) => {debtor.name = e.target.value ;setDebtor({...debtor})} }
            fullWidth
            autoComplete="name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="debtor-address1"
            name="address1"
            label={t("Address") + " 1"}
            value={debtor.address}
            onChange={(e) => {debtor.address = e.target.value ;setDebtor({...debtor})} }
            fullWidth
            autoComplete="address-line1"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="debtor-address2"
            name="address2"
            label={t("Address") + " 2"}
            fullWidth
            autoComplete="address-line2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="debtor-city"
            name="city"
            label={t("City")}
            value={debtor.city}
            onChange={(e) => {debtor.city = e.target.value ;setDebtor({...debtor})} }
            fullWidth
            autoComplete="address-level2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="debtor-state"
            name="state"
            label={t("State/Province/Region")}
            value={debtor.state}
            onChange={(e) => {debtor.state = e.target.value ;setDebtor({...debtor})} }
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="debtor-zip"
            name="zip"
            label={t("Zip / Postal code")}
            value={debtor.zip}
            onChange={(e) => {debtor.zip = e.target.value ;setDebtor({...debtor})} }
            fullWidth
            autoComplete="postal-code"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="debtor-country"
            name="country"
            label={t("Country")}
            value={debtor.country}
            onChange={(e) => {debtor.country = e.target.value ;setDebtor({...debtor})} }
            fullWidth
            autoComplete="country"
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}