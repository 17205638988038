import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DoneIcon from '@mui/icons-material/Done';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useLinkClickHandler } from 'react-router-dom'
import { RequireNonAuth, useAuth } from '../../Context/AuthContext';
import { LoadingButton } from '@mui/lab';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a color="inherit" href="https://schwager.beer/">
        Schwager Bräu
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ResetEmail() {
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [t] = useTranslation()
  const auth = useAuth()

  const handleClick = useLinkClickHandler("/login")

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    auth.resetEmail(data.get("username")).then(() => { 
      setSuccess(true)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  };

  return (
    <RequireNonAuth>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Password Recovery")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("Username")}
              name="username"
              autoComplete="username"
              autoFocus
            />
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              disabled={loading || success}
              startIcon={success ? <DoneIcon /> : <SendIcon />}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("Send Recovery Email")}
            </LoadingButton>
            <Button
              fullWidth
              variant="contained"
              color="inherit"
              sx={{ mt: 0, mb: 2 }}
              onClick={handleClick}
            >
              {t("Back to login")}
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </RequireNonAuth>
  );
}