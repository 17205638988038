import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link, useLinkClickHandler } from 'react-router-dom'
import { RequireNonAuth, useAuth } from '../../Context/AuthContext';
import { LoadingButton } from '@mui/lab';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a color="inherit" href="https://schwager.beer/">
        Schwager Bräu
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const [loading, setLoading] = React.useState(false)
  const [t] = useTranslation()
  const auth = useAuth()

  const handleClick = useLinkClickHandler("/")

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    auth.login(data.get("username"), data.get("password")).then(() => { 
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  };

  return (
    <RequireNonAuth>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Login")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("Username")}
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("Remember me")}
            />*/}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("Login")}
            </LoadingButton>
            <Button
              fullWidth
              variant="contained"
              color="inherit"
              sx={{ mt: 0, mb: 2 }}
              onClick={handleClick}
            >
              {t("Continue without login")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/reset">
                  {t("Forgot password?")}
                </Link>
              </Grid>
              <Grid item>
                <Link to="/register">
                  {t("Don't have an account?")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </RequireNonAuth>
  );
}