import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service';
import { useAuth } from '../../Context/AuthContext';

export default function UserForm(params) {
  const user = params.user
  const setUserData = params.setUserData
  const [t] = useTranslation()
  const auth = useAuth()

  React.useEffect(() => {
    UserService.getUserData().then((data) => {
      setUserData({ 
        ...user,
        ...data.data
      })
    }).catch(() => { auth.logout() })
  }, [])
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("Your Data")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label={t("Name")}
            value={user.name}
            onChange={(e) => {user.name = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="iban"
            name="iban"
            label={t("IBAN")}
            value={user.account}
            onChange={(e) => {user.account = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="iban"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label={t("Address") + " 1"}
            value={user.address}
            onChange={(e) => {user.address = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="address-line1"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label={t("Address") + " 2"}
            fullWidth
            autoComplete="address-line2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label={t("City")}
            value={user.city}
            onChange={(e) => {user.city = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="address-level2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label={t("State/Province/Region")}
            value={user.state}
            onChange={(e) => {user.state = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="state"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label={t("Zip / Postal code")}
            value={user.zip}
            onChange={(e) => {user.zip = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="postal-code"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label={t("Country")}
            value={user.country}
            onChange={(e) => {user.country = e.target.value ;setUserData({...user})} }
            fullWidth
            autoComplete="country"
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}