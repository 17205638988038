import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Articles from './Articles';
import Debtor from './Debtor';
import User from './User';
import { useTranslation } from "react-i18next"
import generateBill from "./generateBill"
import { MenuItem, Select } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Schwager Bräu
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Your Data', 'Articles', 'Bill Data'];

const theme = createTheme();

export default function Bill() {
  const [t, i18n] = useTranslation()
  let [user, setUserData] = React.useState({name: "", address: "", zip: "", city: "", country: "", account: ""})
  let [bill, setBillData] = React.useState({nr: 1, currency: ""})
  let [debtor, setDebtor] = React.useState({name: "", address: "", zip: "", city: "", country: ""})
  let [vat, setVat] = React.useState({use: false, vat: 0.077})
  let [articles, setArticles] = React.useState([])

  const onClick = () => generateBill(t, user, bill, debtor, articles, vat.use, vat.vat).then((url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${t("Bill")}-${bill.nr}.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  })
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  let stepView = <></>
  switch (activeStep) {
    case 0:
      stepView = <User user={user} setUserData={setUserData} />;
      break;
    case 1:
      stepView = <Articles articles={articles} setArticles={setArticles} vat={vat} setVat={setVat} />;
      break;
    case 2:
      stepView = <Debtor bill={bill} setBillData={setBillData} debtor={debtor} setDebtor={setDebtor} />;
      break;
    default:
      break;
  }

  const changeLanguage = (evt) => i18n.changeLanguage(evt.target.value); // This will also update the metaData state. It's cleaner then switching i18n on metaDataState change.

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar sx={{justifyContent: "space-between"}}>
          <Typography variant="h6" color="inherit" noWrap>
            Schwager Bräu Billing Tool
          </Typography>
          
          <Select sx={{ m: 2 }} labelId="language" label="Languages" variant="standard" size="small" value={i18n.language} onChange={changeLanguage}>
              <MenuItem key="en" value="en">English</MenuItem>
              <MenuItem key="de" value="de">Deutsch</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            {t("Create new Bill")}
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Successfully generated
                </Typography>
                <Typography variant="subtitle1">
                  Saved the bill into your profile.
                </Typography>
                <Button onClick={onClick()}>Print it?</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {stepView}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Generate' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
