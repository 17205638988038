import api from "./api";
import TokenService from "./token.service";

const register = (username, email, password) => {
  return api.post("/auth/signup", {
    username,
    email,
    password
  });
};

const login = (username, password) => {
  return api
    .post("/auth/signin", {
      username,
      password
    })
    .then((response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const logout = () => {
  TokenService.removeUser();
};

const resetEmail = (username) => {
  return api
    .post("/auth/resetemail", {
      username
    })
    .then((response) => {
      return response.data;
    });
};

const resetPW = (token, password) => {
  TokenService.setUser({accessToken: token})
  return api
    .post("/auth/resetpassword", {
      password
    })
    .then((response) => {
      TokenService.removeUser()
      return response.data;
    })
    .catch((err) => {
      TokenService.removeUser()
      return err
    });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  resetEmail,
  resetPW,
  getCurrentUser,
};

export default AuthService;
